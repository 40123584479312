.servers-view-wrap {
  padding: 16px 16px 16px 16px;
  max-height: calc(100vh - 8em);
  overflow: auto;
  display: flex;
}
.servers-header-label {
  font-weight: 500 !important;
  padding-top: 2px;
}
.servers-header-container {
  padding: 5px 10px;
  border-radius: 3px;
}
.servers-body {
  margin-top: 15px;
}
.servers-container {
  background-color: #fff;
  padding: 40px 40px 10px 40px;
}
.server-header-label {
  font-weight: 700;
}
.servers-input-wrap {
  margin-top: 18px;
}
.servers-search-input {
  width: 280px;
}
.filter-toggle-wrap {
  display: flex;
}
.toggle-view-wrap {
  margin-left: 30px;
  margin-top: 5px;
}
.row-view .hidden-cell div {
  visibility: hidden !important;
}
.add-server-button {
  right: 60px;
}
.add-server-button-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
}
.add-server-button .ABB_CommonUX_Button__text {
  margin: 0px !important;
  width: 0px !important;
}
.servers-grid-wrap {
  overflow: auto;
  min-height: calc(100vh - 26em);
  margin-top: 5px;
  max-height: calc(100vh - 26em);
}
.sortable-header i {
  margin-top: -1px;
  height: 14px;
}
.toggle-view-wrap .ABB_CommonUX_ToggleButton__icon {
  padding: 0px 8px 5px 7px !important;
}
