.certificate-row {
  display: flex;
}
.security-certificate-label-wrap {
  padding-top: 10px;
}
.replace-certificate-button-wrap {
  margin-left: 120px;
}
.secure-connection-text {
  padding-top: 22px;
  padding-left: 20px;
}
.replace-certificate-note-wrap {
  display: flex;
}
.replace-certificate-note-wrap span {
  margin-left: 10px;
}
.modal-submit-cancel {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 10px 0px;
}
.modal-submit-cancel button {
  margin-left: 10px;
}
.server-modal-form {
  min-width: 400px;
}
.server-modal {
  min-width: 750px;
}
.hidden-row {
  display: none;
}
.connection-info-label {
  color: #9f9f9f;
  margin-top: 40px;
}
.upload-nodeset-row {
  padding-left: 0px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 50px;
}
.last-uploaded-label {
  padding-left: 7px;
  padding-top: 9px;
}
.file-upload-utton-wrap {
  display: flex;
}
.upload-status {
  padding-top: 8px;
}
.nodeset-file-upload-wrap {
  margin-top: 10px;
  margin-left: 0px;
}
.nodeset-file-configure {
  display: flex;
}
.configure-nodeset-label {
  font-size: 14px;
  padding: 8px 5px 0px 15px;
}
.each-name-wrap {
  display: flex;
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 4px;
}
.each-name-wrap .file-close-icon {
  margin-left: 5px;
  margin-top: 1px;
  cursor: pointer;
}
.ABB_CommonUX_Dialog__dark .each-name-wrap {
  background-color: #0f0f0f !important;
  border: 1px solid #333 !important;
}

.file-upload-container {
  display: flex;
  padding-left: 10px;
  justify-content: flex-start;
}
.file-upload-button-wrap {
  margin-left: 20px;
}
.file-name-wrap {
  border: 1px solid #dbdbdb;
  height: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-height: 32px;
  flex-wrap: wrap;
}

.upload-dialog .ABB_CommonUX_Dialog__content {
  width: 600px !important;
}
.submit-cancel-view-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.submit-cancel-view-wrap button {
  margin-left: 10px;
}
.connect-server-inputs {
  margin-top: 32px;
}
.edge-selection-dropdown {
  margin-top: 20px;
}
.add-server-modal-devider {
  margin-top: 38px;
  margin-bottom: 20px;
}
.server-modal .ABB_CommonUX_Dialog__content {
  overflow: auto;
  max-height: calc(100vh - 100px) !important;
}
.upload-modal .ABB_CommonUX_Dialog__root > .ABB_CommonUX_Dialog__content {
  padding: 16px 9px 8px 32px !important;
}
.server-modal .ABB_CommonUX_Dialog__root > .ABB_CommonUX_Dialog__content {
  padding: 16px 25px 8px 32px !important;
}
.upload-button-loading {
  position: absolute !important;
  margin-top: 4px !important;
  margin-left: 5px !important;
}
.uploading-button {
  padding-left: 20px;
}
.hidden-view {
  display: none !important;
}
.could-not-fetch-status-message {
  font-weight: 700;
}
.could-not-fetch-status-message-two {
  color: #696969;
}
.could-not-fetch-status-line-one {
  display: flex;
}
.could-not-fetch-status-line-one .icon {
  margin-top: 2px;
  margin-right: 3px;
  color: #36f;
}
.could-not-fetch-status-line-one .time-view {
  color: #696969;
  font-weight: 100 !important;
}
.could-not-fetch-status-line-one .error-icon {
  margin-top: 2px;
  margin-right: 3px;
  color: #f03040;
}
.disable-message-wrap {
  display: flex;
}
.disable-message-wrap .icon-wrap {
  display: flex;
  margin-top: -4px;
  margin-right: 11px;
}
.upload-button-loader-wrap {
  position: relative;
}
.upload-button-loader-wrap
  .ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial
  > div
  > svg
  > g
  > circle.ABB_CommonUX_LoadingIndicator__back {
  stroke: #dbdbdb !important;
}
.upload-button-loader-wrap
  .ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__dark.ABB_CommonUX_LoadingIndicator__radial
  > div
  > svg
  > g
  > .ABB_CommonUX_LoadingIndicator__back {
  stroke: #dbdbdb !important;
}
.upload-dialog .ABB_CommonUX_Dialog__root > .ABB_CommonUX_Dialog__content {
  padding: 16px 8px 8px 32px !important;
}
.disabled-page-message {
  font-weight: 700;
}
.note-icon-wrap {
  padding-top: 2px;
}
.certificate-file-name-view {
  padding-left: 10px;
}
.disable-message-wrap .success-icon {
  color: rgb(12, 169, 25);
  margin-top: 2px;
  margin-right: 3px;
}
.upload-modal-browse-server {
  color: #36f !important;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}
