.tree-body-view {
  min-width: 300px;
}
.tree-body-view .each-node-label {
  font-size: 14px;
  font-weight: 600;
  color: #1f1f1f;
  cursor: pointer;
}
.tree-body-view .each-node-view {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  cursor: pointer;
  border-left: 4px solid;
  border-color: transparent;
}
.tree-body-view .node-disabled {
  pointer-events: none;
}
.tree-body-view .each-node-view:hover {
  background-color: #dbdbdb;
}
.tree-body-view .hidden-tree-class {
  display: none;
}
.tree-body-view .node-view-right-icon {
  cursor: pointer;
}
.tree-body-view .node-view-right-icon i:hover {
  font-size: 19px !important;
}
.tree-body-view .node-left-space {
  width: 25px;
  text-align: center;
}

.tree-body-view .tree-icon {
  font-size: 14px;
  padding-bottom: 4px;
}
.tree-body-view .node-selected {
  background-color: #dbdbdb;
  border-color: #36f !important;
}
.tree-body-view .node-view-right-icon .ABB_CommonUX_Icon__root {
  margin-top: 5px;
}
.tree-body-view .individual-icon-wrap {
  padding-left: 5px;
  padding-right: 9px;
  padding-top: 5px;
}
.dark-view-container .tree-body-view .each-node-view:hover {
  background-color: #333;
}
.dark-view-container .tree-body-view {
  color: #dbdbdb !important;
  background-color: #0f0f0f !important;
  /* border: 1px solid #464646 !important; */
}
.dark-view-container .tree-body-view .each-node-label {
  color: #dbdbdb !important;
}
.dark-view-container .browse-body .browse-tree-wrap {
  color: #dbdbdb !important;
  background-color: #0f0f0f !important;
  border: 1px solid #464646 !important;
}
.dark-view-container .tree-body-view .node-selected {
  background-color: #333 !important;
}
.dark-view-container .browse-right-pannel {
  background-color: #333;
  border: 1px solid #464646 !important;
}
