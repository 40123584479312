.browse-view-grid .sorted-column {
  border-bottom: 4px solid #2e92fa !important;
}
.browse-view-grid td {
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: unset !important;
  word-wrap: break-word;
  word-break: break-word;
}
.browse-view-grid .actions-cell {
  text-align: right;
  width: 50px;
}
.browse-view-grid .actions-header-column {
  width: 100px;
}
.browse-view-grid .actions-filter-column {
  width: 100px;
}
.browse-view-grid .click-class {
  cursor: pointer;
}
.browse-view-grid .checkBox-header-column {
  width: 30px;
}
.browse-view-grid .checkBox-filter-column {
  width: 30px !important;
}
.filter-icon-grid {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.filter-toggle {
  text-align: right;
}
.browse-view-grid .check-box-cell {
  width: 30px;
  padding: 0px !important;
}
.sortable-check-box-cell {
  width: 60px !important;
  display: flex;
}
.check-box-sort-icon-box-wrap {
  display: flex;
}
.check-box-sort-icon-box-wrap .check-box-sort-icon-wrap {
  padding-top: 10px;
}
.browse-view-grid .header-content-wrap {
  display: flex;
  justify-content: space-between;
}
.browse-view-grid .filter-content-wrap {
  max-width: 300px;
}
.browse-view-grid .hidden-filter {
  display: none;
}
.browse-view-grid .sortable-header {
  cursor: pointer;
}
.browse-view-grid .loading-container {
  min-height: 100px;
  display: flex;
  justify-content: center;
  margin-left: 50%;
  flex: 1;
  width: 100%;
}
.browse-view-grid .no-data-container {
  text-align: center !important;
}
.browse-view-grid .action-icons-container {
  display: flex;
  justify-content: flex-end;
}
.browse-view-grid .table-action-icon {
  cursor: pointer;
}
.browse-view-grid .action-icon-wrap {
  width: 40px;
}
.browse-view-grid .action-cell .action-disabled {
  pointer-events: none;
  opacity: 0.4;
}

/*********column resize css************/
.browse-table {
  display: block;
  box-sizing: border-box;
}
.browse-table th {
  position: relative;
  white-space: nowrap;
  text-align: left;
}
.browse-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.browse-table .filter-row-thead {
  position: sticky;
  top: 30 !important;
  z-index: 1;
}
.browse-table td {
  white-space: nowrap;
}
.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}
.browse-table .resizer:hover {
  z-index: 10000000;
}
.browse-table tr th,
td {
  padding-left: 10px;
  padding-right: 10px;
}
.browse-table .expand-header-column,
.expansion-cell {
  min-width: 20px !important;
  border-right: none;
}
.browse-table .actions-header-column,
.action-cell {
  border-right: none;
}
.browse-table td {
  display: table-cell;
}
.browse-table th {
  display: table-cell;
}
.server-each-body-col {
  display: flex;
  flex-wrap: wrap;
}
.browse-table th {
  box-shadow: inset 0 -0.5px 0 #000000;
}
.browse-table .sorted-header {
  box-shadow: inset 0 -4px 0 rgb(46, 146, 250);
  border-bottom: none !important;
}
.browse-table .each-header-cell {
  padding: 0px;
}
.browse-table .each-header-cell {
  background-color: #fff !important;
}
.browse-table .each-header-cell:hover {
  background: rgb(235, 235, 235) !important;
  border-right: 2px solid #696969;
}
.dark-view-container .browse-table .each-header-cell {
  background-color: #1f1f1f !important;
}
.dark-view-container .browse-table .each-header-cell:hover {
  background: #333 !important;
  border-right: 2px solid #696969;
}
.browse-table .resizer:hover {
  border-right: 2px solid #696969;
  z-index: 10000000;
}
.browse-table .header-content-wrap {
  padding-left: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-right: 2px;
  padding-right: 16px;
  margin-left: 2px;
}
.ABB_CommonUX_Dialog__dark .each-header-cell {
  background-color: #1f1f1f !important;
}
.ABB_CommonUX_Dialog__dark .each-header-cell:hover {
  background: #333 !important;
  border-right: 2px solid #696969;
}
