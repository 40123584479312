/* .App {
  text-align: center;
} */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666;
}
body h4,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-weight: 400;
}
body {
  font-family: "ABBVoice";
}
.ABB_CommonUX_LoginScreen__root
  > .ABB_CommonUX_LoginScreen__header
  > .ABB_CommonUX_LoginScreen__logo {
  /* background: url(/logo.png) no-repeat !important; */
  background-size: contain;
  display: block;
  height: 50px;
  width: 80px;
}
.ABB_CommonUX_Dialog__root > .ABB_CommonUX_Dialog__content {
  padding: 16px 32px 8px 32px !important;
}
.light-theme {
  background-color: #fff;
  color: #1f1f1f;
}
.dark-theme {
  color: #dbdbdb !important;
  background-color: #333 !important;
}
.content-dark-theme {
  color: #dbdbdb !important;
  background-color: #0f0f0f !important;
}
.dark-view-container {
  background-color: #333 !important;
}
