.sorted-column {
  border-bottom: 4px solid #2e92fa !important;
}
td {
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: unset !important;
  word-wrap: break-word;
  word-break: break-word;
}
.actions-cell {
  text-align: right;
  width: 50px;
}
.actions-header-column {
  width: 100px;
}
.actions-filter-column {
  width: 100px;
}
.click-class {
  cursor: pointer;
}
.checkBox-header-column {
  width: 30px;
}
.expand-header-column {
  width: 20px;
}
.expansion-cell {
  width: 20px;
  padding: 0px !important;
}
.servers-grid-wrap .expansion-cell i {
  margin-left: 10px;
  cursor: pointer;
  margin-top: 2px;
}
.check-box-cell {
  width: 30px !important;
  padding: 0px !important;
}
.header-content-wrap {
  display: flex;
  justify-content: space-between;
}
.sortable-header {
  cursor: pointer;
}
.loading-container {
  min-height: 100px;
  display: flex;
  justify-content: center;
  margin-left: 50%;
  flex: 1;
  width: 100%;
}
.no-data-container {
  text-align: center !important;
}
.action-icons-container {
  display: flex;
  justify-content: flex-end;
}
.table-action-icon {
  cursor: pointer;
}
.action-icon-wrap {
  width: 40px;
  padding-top: 1px;
}
.action-cell .action-disabled {
  pointer-events: none;
  opacity: 0.4;
}
.browse-disabled {
  pointer-events: none;
  opacity: 0.4;
}
.hidden-row-view {
  display: none;
}
.server-padding {
  padding-left: 20px;
}

tr:not(:last-child) .server-expansion-cell {
  border-bottom: none !important;
}
.edge-expansion-cell {
  border-top: 1px solid #bababa;
}
.faded-row-view td {
  border-bottom-color: #eaeaea !important;
}
.no-border-bottom td {
  border-bottom: none;
}
.status-view-wrap {
  display: flex;
}
.server-status-label {
  display: flex;
}
.disabled-status {
  opacity: 0.5;
}
.pop-up-view {
  height: 102px;
  width: 220px;
  position: absolute;
  z-index: 100;
  margin-left: -180px;
  margin-top: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px 1px;
}
.dark-view-container .pop-up-view {
  color: #dbdbdb !important;
  background-color: #0f0f0f !important;
}
.pop-up-wrap-status {
  /* pointer-events: none; */
  cursor: default;
}
.pop-up-view-status {
  height: 146px;
  width: 340px;
  position: absolute;
  z-index: 100;
  margin-top: -191px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px 1px;
  margin-left: -135px;
  padding: 16px 16px 20px 16px;
  padding: 15px;
  overflow: auto;
  transform-origin: top;
}
.dark-view-container .pop-up-view-status {
  color: #dbdbdb !important;
  background-color: #0f0f0f !important;
}
.pop-up-arrow-status {
  height: 12px;
  width: 12px;
  position: absolute;
  background: rgb(255, 255, 255);
  transform: rotate(-45deg);
  z-index: 80;
  box-shadow: none;
  box-sizing: border-box;
  border-top: 1px solid rgb(235, 235, 235);
  border-right: 1px solid rgb(235, 235, 235);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px 1px;
  margin-left: 18px;
  margin-top: -51px;
}
.dark-view-container .pop-up-arrow-status {
  color: #dbdbdb !important;
  background-color: #0f0f0f !important;
  border-top: 1px solid #0f0f0f;
  border-right: 1px solid #0f0f0f;
}
.pop-up-view-status-header {
  font-weight: 600;
}
.pop-up-view-status-details {
  margin-top: 16px;
}
.more-option-popup-row {
  display: flex;
  padding-left: 10px;
  line-height: 21px;
  padding-bottom: 4px;
}
.more-option-popup-row:hover {
  background-color: #eaeaea;
  cursor: pointer;
}
.dark-view-container .more-option-popup-row:hover {
  background-color: #eaeaea;
  cursor: pointer;
  background-color: #333 !important;
}
.more-option-popup-row div {
  margin-top: 7px;
}
.pop-up-view i {
  margin: 5px 10px 0px 5px;
}
.popup-arrow {
  height: 12px;
  width: 12px;
  position: absolute;
  background: rgb(255, 255, 255);
  transform: rotate(-45deg);
  z-index: 80;
  box-shadow: none;
  box-sizing: border-box;
  border-top: 1px solid rgb(235, 235, 235);
  border-right: 1px solid rgb(235, 235, 235);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 12px 1px;
  /* right: 61px; */
  margin-left: 25px;
}
.dark-view-container .popup-arrow {
  background: #333 !important;
  /* background-color:  !important; */
  border-top: 1px solid #0f0f0f;
  border-right: 1px solid #0f0f0f;
}
.pop-up-overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 70;
}
.status-icon-wrap {
  display: flex;
  margin-right: 5px;
  width: 30px;
}
.status-icon-wrap .ABB_CommonUX_LoadingIndicator__root {
  margin-top: -5px;
  margin-left: -3px;
  color: #dbdbdb;
}
.status-icon-wrap
  .ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial
  > div
  > svg
  > g
  > circle.ABB_CommonUX_LoadingIndicator__back {
  stroke: #dbdbdb !important;
}
.status-icon-wrap
  .ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__dark.ABB_CommonUX_LoadingIndicator__radial
  > div
  > svg
  > g
  > .ABB_CommonUX_LoadingIndicator__back {
  stroke: #dbdbdb !important;
}

.server-status-info {
  margin-left: 5px;
  margin-top: 2px;
  cursor: default;
}
.status-left-div {
  height: 10px;
  width: 2px;
}
.cell span {
  display: flex;
  align-items: center;
}
.cell .status-icon-wrap {
  display: flex;
  align-items: center;
}

/***********column resize css***********/
.server-table {
  display: block;
  box-sizing: border-box;
}
.server-table th {
  position: relative;
  white-space: nowrap;
  text-align: left;
  box-shadow: inset 0 -0.5px 0 #000000;
}
.server-table .sorted-header {
  box-shadow: inset 0 -4px 0 rgb(46, 146, 250);
  border-bottom: none !important;
}
.server-table td {
  white-space: nowrap;
}
.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}

.server-table .each-header-cell {
  background-color: #fff !important;
}
.server-table .each-header-cell:hover {
  background: rgb(235, 235, 235) !important;
  border-right: 2px solid #696969;
}

.dark-view-container .server-table .each-header-cell {
  background-color: #1f1f1f !important;
}
.dark-view-container .server-table .each-header-cell:hover {
  background: #333 !important;
  border-right: 2px solid #696969;
}
.server-table .resizer:hover {
  border-right: 2px solid #696969;
  z-index: 10000000;
}
.server-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.server-table tr th,
td {
  padding-left: 10px;
  padding-right: 10px;
}
.server-table .expand-header-column,
.expansion-cell {
  min-width: 20px !important;
  border-right: none;
}
.server-table .actions-header-column,
.action-cell {
  /* min-width: 20px !important; */
  border-right: none;
  padding-right: 2px !important;
  text-align: right !important;
}
.server-table td {
  display: table-cell;
}
.server-table th {
  display: table-cell;
}
.server-each-body-col {
  display: flex;
  flex-wrap: wrap;
}
.server-table .each-header-cell {
  padding: 0px;
}
.server-table .header-content-wrap {
  padding-left: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-right: 2px;
  padding-right: 16px;
  margin-left: 2px;
}
.server-table .pop-up-wrap {
  position: relative;
}
