.browse-top-pannel {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.browse-body {
  display: flex;
}
.configured-data-grid-view,
.enabled-method-data-grid-view {
  padding: 0px 16px 8px 16px !important;
  max-width: calc(100vw - 75px);
  /* min-height: calc(100vh - 21em); */
  /* max-height: calc(100vh - 27em);*/
  overflow: auto;
  overflow-y: hidden;
}
.configured-data-grid-wrap,
.enabled-method-data-grid-wrap {
  margin-top: 30px;
}
.configured-data-container {
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  min-height: calc(100vh - 14em);
  max-height: calc(100vh - 13em);
  overflow: auto;
}
.configured-data-sub-view-header {
  cursor: pointer;
}
.configured-data-sub-view-header i {
  cursor: pointer;
  margin-top: 2px;
}
.configured-data-sub-view-header-container {
  border-bottom: 1px solid;
  padding: 0px 10px;
  padding-top: 20px;
}
.configured-data-sub-view-header-label,
.configured-data-sub-view-header-icon-wrap {
  display: flex;
  align-items: center;
}
.browse-wrap {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-flow: column;
}
.browse-body .browse-tree-wrap {
  background-color: #fff;
}
.browse-body .tree-body-wrap {
  display: inline-block;
  min-width: 350px;
}
.browse-body .tree-body-view {
  width: 362px;
  max-height: calc(100vh - 12em);
  min-height: calc(100vh - 160px);
  overflow: auto;
  display: flex;
  background-color: #fff;
}
.browse-tree-empty {
  width: 350px;
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #fff;
}
.dark-view-container .browse-tree-empty {
  background-color: #0f0f0f;
}
.browse-right-pannel {
  width: calc(100vw - 380px);
  min-height: calc(100vh - 160px);
  max-height: calc(100vh - 175px);
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: 5px;
  overflow: auto;
  padding: 10px 5px 8px 15px !important;
}
.dark-view-container .browse-top-middle {
  color: #dbdbdb !important;
}
.browse-top-middle {
  flex: 1;
  text-align: center;
  margin-top: 10px;
}
.browse-top-end {
  margin-right: 20px;
  flex: 1;
  text-align: right;
}
.server-navigation-text {
  color: #36f;
}
.configured-data-buttons {
  margin: 20px 0px 10px 8px;
}
.configured-data-buttons button {
  margin-left: 10px;
}
.active-column-cell-content {
  padding-top: 7px;
}
.paused-column-cell-content {
  opacity: 0.5;
}
.paused-column-cell-content i {
  padding-top: 5px;
}
.isactive-header {
  width: 60px;
}
.isactive-column {
  width: 60px;
}
.configured-data-title {
  font-size: 18px;
  margin-top: 12px;
}
.browse-toggle-view {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
.browse-top-left {
  display: flex;
  padding-left: 10px;
  margin-top: 10px;
  flex: 1;
  flex-wrap: wrap;
}
.bread-crumb-devider {
  margin-left: 5px;
  margin-right: 5px;
}
.dark-view-container .bread-crumb-devider {
  color: #dbdbdb;
}
.bread-crumb-cell {
  color: #696969;
  font-weight: 800;
  font-family: ABBVoice-bold;
}
.bread-crumb-server {
  color: #36f;
  cursor: pointer;
}
.bread-crumb-server:hover {
  text-decoration: underline;
}
.node-details-view .ABB_CommonUX_Dialog__root > .ABB_CommonUX_Dialog__content {
  padding: 2px 9px 32px 32px !important;
}
.browse-right-pannel-mask {
  cursor: not-allowed;
  position: absolute;
  z-index: 5000;
  width: calc(100vw - 380px);
  height: 100%;
  margin: -10px -5px -8px -15px;
}
.browse-right-pannel-contet-disabled {
  opacity: 0.5;
}
.right-pannel-mask-messege {
  font-size: 14px;
  padding: 20px;
  opacity: 0.8;
  background: rgb(255, 115, 0);
  color: #fff;
  display: flex;
}
.right-pannel-warning-icon {
  margin-right: 10px;
  padding-top: 2px;
}
