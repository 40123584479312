.right-pannel-view {
  padding: 10px;
}
.each-row-node-details {
  display: flex;
  padding-top: 20px;
}
.form-inputs-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}
.desired-type-copy-icon-wrap {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  color: #36f;
}
.desired-type-copy-icon-wrap i:hover {
  color: #36f;
  font-size: 18px;
}
.form-inputs-wrap .disabled-view {
  color: unset !important;
  opacity: 0.5;
}
.form-inputs-wrap .disabled-view i:hover {
  color: unset !important;
  font-size: 16px;
}
.each-column-node-details {
  margin-left: 8px;
  font-weight: 800;
  word-wrap: break-word;
  word-break: break-all;
}
.variable-each-row {
  display: flex;
}
.variable-each-row:hover {
  background-color: #ddd;
}
.variable-first-column {
  flex: 0.05;
}
.variable-name {
  padding-top: 7px;
  flex: 0.3;
}
.variable-type-id {
  padding-top: 7px;
  flex: 0.4;
}
.variable-browse-name {
  padding-top: 7px;
  flex: 0.3;
}
.columnResizer {
  background-color: #ccc;
}
.each-row-form {
  flex: 1;
}
.each-row-form .each-column-node-details-header {
  margin-top: 5px;
}
.right-pannel-title {
  width: 100%;
  text-align: left;
  margin-right: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}
.right-pannel-title .node-form-details-icon {
  margin-top: 2px;
  cursor: pointer;
}
.right-pannel-title-label {
  padding-top: 10px;
}
.variables {
  border: 2px solid #ddd;
  padding-bottom: 10px;
  min-width: 600px;
}
.desired-variable-title {
  display: flex;
  min-width: 600px;
}
.select-all-checkbox-view {
  flex: 0.05;
}
.variable-name-header {
  flex: 0.3;
  padding-top: 7px;
  font-weight: 800;
  padding-left: 8px;
}
.variable-type-id-header {
  flex: 0.4;
  padding-top: 7px;
  font-weight: 800;
}
.variable-browse-name-header {
  flex: 0.3;
  padding-top: 7px;
  font-weight: 800;
}
.desired-variable-title-label {
  padding-top: 20px;
  font-weight: 800;
}
.collect-disable-message-view {
  color: #f03040;
  display: flex;
  flex-direction: row-reverse;
  margin-right: 25px;
  position: absolute;
  bottom: 33px;
  z-index: 1000000;
}
.disabled-message-hidden {
  visibility: hidden;
}
.node-form-buttons {
  display: flex;
  /* flex-direction: row-reverse; */
}
.node-form-button-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  position: absolute;
  z-index: 1000;
  bottom: 20px;
  width: calc(100vw - 415px);
  background-color: #fff;
}
.dark-view-container .node-form-button-container {
  background-color: #333 !important;
}
.node-form-buttons button {
  margin: 10px 0px 10px 10px;
  border: 1px solid;
  width: 12%;
}

.node-form-buttons .collect-button {
  font-weight: 900 !important;
  background-color: #cacaca;
  margin: 10px;
}
.variables {
  max-height: 500px;
  overflow: auto;
}
.type-invalid-message {
  color: #f03040;
  display: flex;
  font-size: 14px;
}
.disabled-desiredType-hidden {
  visibility: hidden;
}
.submit-cancel-warning-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.submit-cancel-warning-wrap button {
  margin-left: 10px;
}
.warning-dialog-modal .ABB_CommonUX_Dialog__content {
  padding: 16px 25px 8px 32px !important;
}
.disabled-variable-row td {
  opacity: 0.5;
  cursor: not-allowed;
}
/***************/

.variables-view,
.methods-view {
  overflow: auto;
  max-height: calc(100vh - 36em);
  padding-bottom: 10px;
}
.input-params-view,
.output-params-view {
  overflow: auto;
  max-height: calc(100vh - 52em);
  padding-bottom: 10px;
}
.variables-view-wrap,
.output-params-table-wrap,
.input-params-table-wrap,
.methods-table-wrap {
  margin-top: 40px;
}
.input-output-params-modal {
  max-width: 1500px;
}
.methods-view-wrap {
  margin: 60px 0px;
}
.input-output-params-view {
  margin-bottom: 30px;
}
.methods-view .browse-table .disabled-variable-row .action-cell {
  opacity: 1;
  background: rgba(255, 255, 255, 0.5) !important;
}
.methods-view .browse-table .disabled-variable-row:hover .action-cell {
  opacity: 1;
  background: rgba(235, 235, 235, 0.5) !important;
}
.dark-view-container
  .methods-view
  .browse-table
  .disabled-variable-row
  .action-cell {
  opacity: 1;
  background: #1f1f1f !important;
}
.dark-view-container
  .methods-view
  .browse-table
  .disabled-variable-row:hover
  .action-cell {
  opacity: 1;
  background: #333 !important;
}
.unsupported-message {
  opacity: 0.6;
}
.method-error-unsupported {
  display: flex;
}
.method-info-warning-icon {
  margin-right: 10px;
}
